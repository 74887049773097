<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('you-hui-quan') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-10 justify-content-center">
      <div class="col-md-4 mx-auto mb-3" >
        <a @click="add">
          <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
            <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
              <i class="las la-plus la-3x text-white"></i>
            </span>
            <div class="fs-18 text-primary">{{ $t('xin-zeng-you-hui-quan') }}</div>
          </div>
        </a>
      </div>
    </div>

      <div class="card">
      <div class="card-header row gutters-5">
          <div class="col">
              <h5 class="mb-md-0 h6">{{ $t('suo-you-you-hui-quan') }}</h5>
          </div>
      </div>
      <div class="card-body">
        <table class="table p-0">
          <thead>
            <tr>
              <th data-breakpoints="lg">#</th>
              <th>{{ $t('hao-ma') }}</th>
              <th data-breakpoints="lg">{{ $t('lei-xing') }}</th>
              <th data-breakpoints="lg">{{ $t('kai-shi-ri-qi') }}</th>
              <th data-breakpoints="lg">{{ $t('jie-shu-ri-qi') }}</th>
              <th width="10%">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>{{i + 1}}</td>
              <td>{{item.code}}</td>
              <td>{{ $t('zhe-kou-quan') }}</td>
              <td>{{item.CreateAt}}</td>
              <td>{{item.CreateAt}}</td>
              <td class="text-right">
                <a class="btn btn-soft-primary btn-icon btn-circle btn-sm" :title="$t('xiu-gai')">
                  <i class="las la-edit"></i>
                </a>
                <a class="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"  :title="$t('shan-chu')">
                  <i class="las la-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

   
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      form: {
        deliverStatus: '',
        payStatus: '',
        payType: ''
      },
      tableData: [
        {
          code: '1231312312038'
        },
        
      ],
    }
  },
  methods: {
    openModal() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    add() {
      this.$router.push({
        name: 'sellerAddCoupon'
      })
    }
  }
}
</script>